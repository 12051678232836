import { css, jsx } from '@emotion/core';
import { Skeleton } from 'antd';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import Container from '@/src/shared/components/Container';
import Head from '@/src/shared/components/Head';
import Hero from '@/src/shared/components/Hero';
import Layout from '@/src/shared/components/Layout';
import Section from '@/src/shared/components/Section';
import WebLogImage from '@/src/shared/components/WebLogImage';
import { bp, colors, space } from '@/styles';
import { getPath } from '@/utils/utils';

const style = {
  resultsSection: css`
    padding: 0 !important;
    margin: 0 !important;

    @media print {
      background-color: ${colors.white};
    }
  `,
  resultsContainer: css`
    margin-left: -10px !important;
  `,
  noHero: noHero =>
    noHero
      ? css`
          padding-top: 0 !important;
          margin-top: -30px;
        `
      : css``,

  noPrint: css`
    ${space.m.b20};
    @media print {
      display: none;
    }
  `,
  linksList: css`
    margin-top: 10px;

    > ul {
      margin-left: 16px;
    }
  `,
  linkItem: css`
    color: ${colors.primaryBlue};
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 16px;
    &:hover {
      color: ${colors.primaryRed};
    }
  `,
};

const BulletedPage = props => {
  const {
    results,
    title,
    heroContent,
    renderBeforeList,
    loading,
    overrideLast = false,
    noHero,
    noResultsDisplay,
    statecode,
  } = props;

  const filteredResults = (results || []).slice(0, 10);

  const hasResults =
    results &&
    ((results.length && results.length > 1) ||
      (results.length === 1 && results[0].SitePageId !== 0));

  const noResults =
    noResultsDisplay !== undefined ? noResultsDisplay : <p>No recent entries found.</p>;

  return (
    <Layout>
      {title && title.length ? <Head title={title} /> : null}

      {!noHero && (
        <Hero title={title} overrideLast={overrideLast}>
          {heroContent}
        </Hero>
      )}
      <Section css={[style.resultsSection, style.noHero(noHero)]}>
        <Container size="sm" css={[style.resultsContainer]}>
          {renderBeforeList()}
          {loading ? (
            <>
              {<Skeleton title />}
              <Skeleton avatar />
              <Skeleton avatar />
              <Skeleton avatar />
            </>
          ) : (
            <>
              {hasResults ? (
                <div css={style.linksList}>
                  <ul>
                    {filteredResults.map(result => {
                      const [as, href] = getPath(result.Cat1Id, result.SitePageId);
                      return (
                        <li>
                          <Link href={href} as={as} passHref>
                            <a css={style.linkItem}>{result.PageTitle}</a>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                noResults
              )}
            </>
          )}
        </Container>
      </Section>
      <WebLogImage browserPath title={title} sitePageId={statecode} />
    </Layout>
  );
};

BulletedPage.propTypes = {
  heroContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderBeforeList: PropTypes.func,
  loading: PropTypes.bool,
};

BulletedPage.defaultProps = {
  heroContent: null,
  renderBeforeList: () => null,
  loading: false,
};

export default BulletedPage;
