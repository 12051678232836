import { css, jsx } from '@emotion/core';
import { Icon } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import Container from '@/src/shared/components/Container';
import { colors } from '@/styles';

const style = {
  link: css`
    align-items: center;
    color: ${colors.primaryBlue};
    display: inline-flex;

    .anticon {
      margin: 0 1.2rem 0 0;
    }
  `,
  wrapper: css`
    margin: 1.6rem 0;
  `,
};

const AdminLink = ({
  isLoggedIn,
  roles = [],
  PersonID,
  pageID,
  pageType,
  authorId = null,
  editorIds = [],
}) => {
  const hasPermission =
    roles.includes('CMS Admin') ||
    roles.includes('CMS Static Content Admin') ||
    Number(PersonID) === authorId ||
    editorIds.includes(Number(PersonID));

  if (!isLoggedIn || !hasPermission || !pageID || !pageType) return null;

  let adminUrl = '';

  switch (pageType) {
    case 'core':
      adminUrl = 'core-pages';
      break;
    case 'content':
      adminUrl = 'content-pages';
      break;
    case 'publication':
      adminUrl = 'publications';
      break;
    case 'meeting':
      adminUrl = 'meetings';
      break;
    case 'release':
      adminUrl = 'news-releases';
      break;
    default:
      break;
  }

  return (
    <Container>
      <div css={style.wrapper}>
        <a
          css={style.link}
          href={`/admin/applications/cms/${adminUrl}/${pageID}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="edit" theme="filled" />
          Edit Content
        </a>
      </div>
    </Container>
  );
};

export default connect(
  state => {
    const { auth } = state;
    const {
      isLoggedIn,
      role,
      userinfo: { PersonID },
    } = auth;
    return { isLoggedIn, roles: role, PersonID };
  },
  null
)(AdminLink);
