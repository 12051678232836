import { css } from '@emotion/core';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { bp } from '@/styles';
import formatURL from '@/utils/formatURL';

import AttachmentList from './attachmentList';

const style = {
  cardsContainer: css`
    display: block;
    max-width: 768px;
    margin: 0 auto;
    padding-top: 65px;
    @media ${bp.sm} {
      padding-top: 0px;
    }
  `,
};

function Whitepapers(props) {
  const { whitepapers } = props;

  const router = useRouter();
  const { query } = router;
  const [current, setCurrent] = useState(Number(query.page) || 1);
  useEffect(() => {
    setCurrent(query.page ? Number(query.page) : 1);
  }, [query.page]);

  function handlePaginationChange(page) {
    setCurrent(page);
    window.scrollTo({ top: 0 });
    const newURL = formatURL(router.asPath, { page });
    router.push(newURL, newURL, { shallow: true });
  }
  const filteredResults = whitepapers.MediaResults.filter(w => w.Archived.Code === 'N');

  return (
    <div css={style.cardsContainer}>
      <AttachmentList
        {...props}
        results={filteredResults}
        current={current}
        handlePaginationChange={handlePaginationChange}
        loading={whitepapers.MediaLoading}
        cacheKey={whitepapers.CacheKey}
        pagination
      />
    </div>
  );
}

Whitepapers.propTypes = {
  whitepapers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Whitepapers;
