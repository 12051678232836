import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import formatURL from '@/utils/formatURL';

import ListPage from './index';

function ListPagePaginated(props) {
  const router = useRouter();
  const { query } = router;
  const [current, setCurrent] = useState(Number(query.page) || 1);

  useEffect(() => {
    setCurrent(query.page ? Number(query.page) : 1);
  }, [query.page]);

  function handlePaginationChange(page) {
    setCurrent(page);
    if (!props.noHero) {
      const newURL = formatURL(router.asPath, { page });
      router.push(newURL, newURL, { shallow: true });
    }
  }

  return (
    <ListPage
      {...props}
      current={current}
      handlePaginationChange={handlePaginationChange}
      pagination
    />
  );
}

ListPagePaginated.propTypes = {
  heroContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
  renderResultSubtitle: PropTypes.func,
  truncateResultSummary: PropTypes.bool,
  pagination: PropTypes.bool,
};

ListPagePaginated.defaultProps = {
  heroContent: null,
  pageSize: 20,
  renderResultSubtitle: result => new Date(result.PublishDate).toLocaleDateString(),
  truncateResultSummary: false,
  pagination: false,
};

export default ListPagePaginated;
