import request from './axios';

const generatePublicSearchPayload = fieldOptions => {
  return {
    SearchId: null,
    PersonId: null,
    SearchDescription: '',
    FieldOptions: {
      SearchText: '',
      States: [],
      LinesOfBusiness: [],
      Subjects: [],
      Publications: [],
      DayRanges: [],
      PostingTypes: [],
      Committees: [],
      ContentItems: [],
      DateRanges: [],
      Page: null,
      PageSize: null,
      SortField: 'CREATEDDATE',
      SortOrder: 'DESC',
      ...fieldOptions,
    },
  };
};

const publicSearch = fieldOptions => {
  const payload = generatePublicSearchPayload(fieldOptions);
  return request({
    method: 'POST',
    url: '/cms/publicsearch',
    data: payload,
    triggerLoad: false,
  });
};

export default publicSearch;
