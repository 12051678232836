import { useAuth0 } from '@auth0/auth0-react';
import { css, jsx } from '@emotion/core';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Error from '@/pages/_error';
import contentStyle from '@/src/cms/contentStyle';
import AdminLink from '@/src/shared/components/AdminLink';
import BulletedListPage from '@/src/shared/components/BulletedPage/BulletedListPage';
import Container from '@/src/shared/components/Container';
import Head from '@/src/shared/components/Head';
import Heading from '@/src/shared/components/Heading';
import Hero from '@/src/shared/components/Hero';
import Layout from '@/src/shared/components/Layout';
import ListPagePaginated from '@/src/shared/components/ListPage/ListPagePaginated';
import Sanitize from '@/src/shared/components/Sanitize';
import Section from '@/src/shared/components/Section';
import WebLogImage from '@/src/shared/components/WebLogImage';
import useLayoutEffect from '@/src/shared/hooks/useIsomorphicLayoutEffect';
import { bp } from '@/styles';
import request from '@/utils/axios';
import publicSearch from '@/utils/publicSearch';
import { CAT_ID_MAP } from '@/utils/utils';

import ContentGroupItemsControl from './ContentGroupItems';
import Whitepapers from './Whitepapers';

const style = {
  body: [
    contentStyle,
    css`
      padding: 52px 32px;
      background-color: white;
    `,
  ],
  contentGroupBody: css`
    margin-top: -100px;
    @media ${bp.md} {
      margin-top: -180px;
    }
  `,
  relatedTitle: css`
    margin-bottom: 12px;
  `,
  bulletedListTop: css`
    margin: 0 0 50px;
  `,
  bulletedListBottom: css`
    margin: 50px 0 0;
  `,
};

const legacyRoot = process.env.LEGACY_ROOT || process.env.API_ROOT;

const ContentPage = props => {
  const {
    sitepageid,
    pagedata,
    alias,
    bodyStyle,
    mutateBody,
    renderBeforeContent,
    renderOtherType,
    isLoggedIn,
    isLoading,
    roles,
    specialContentDataNoAuth,
  } = props;
  const { loginWithRedirect } = useAuth0();
  const router = useRouter();
  const [error, setError] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const [meta, setMeta] = useState(pagedata ? pagedata.meta : { title: '', description: '' });
  const [hero, setHero] = useState(
    pagedata
      ? pagedata.hero
      : {
        headline: '',
        subTitle: '',
        description: '',
        image: { id: null, alt: '' },
      }
  );
  const [body, setBody] = useState(pagedata ? pagedata.body : '');
  const [loading, setLoading] = useState(!pagedata);
  const [catId, setCatId] = useState('');
  const [doesntExist, setDoesntExist] = useState(false);
  const [relatedSubjectMatters, setRelatedMatters] = useState([]);
  const [displayListTop, setDisplayListTop] = useState([]);
  const [bulletedFormat, setBulletedFormat] = useState([]);
  const [displayListTitle, setDisplayListTitle] = useState([]);
  const _sitepageid = sitepageid || router.query.slug;
  const [canonicalHREF, setCanonicalHREF] = useState('');
  const [contentGroupItems, setContentGroupItems] = useState([]);
  const [isBody, setIsBody] = useState(false);

  useLayoutEffect(() => {
    if (Boolean(body) && !loading && window.location.hash) {
      const hash = document.querySelector(`[name=${window.location.hash.replace('#', '')}]`);
      if (!hash) return;
      const hashScroll = hash.offsetTop;
      const offset = window.innerWidth > 992 ? 80 : 72;
      window.scrollTo(0, hashScroll - offset);
    }
  }, [body, loading]);

  useEffect(() => {
    if (_sitepageid === undefined || !(_sitepageid || alias || !roles?.length)) return;
    (async function mounted() {
      try {
        if (specialContentDataNoAuth) {
          setUnauthorizedError(false);
        } else {
          const securityResponse = await request({
            url: 'cms/contentpagesecurity',
            params: {
              corePageId: _sitepageid,
            },
          });
          const { MemberAccessLevels } = securityResponse.data;
          if (MemberAccessLevels?.length !== 0 && !isLoggedIn && !isLoading) {
            loginWithRedirect({
              appState: {
                returnTo: window.location.pathname,
              },
            });
            return;
          }
          if (MemberAccessLevels !== undefined && MemberAccessLevels?.length !== 0) {
            const hasAccessToContent = MemberAccessLevels.some(level =>
              roles.includes(level.WebAccessDescription)
            );
            if (!hasAccessToContent) {
              setUnauthorizedError(true);
              return;
            }
            setUnauthorizedError(false);
          }
        }
        const response = await request({
          url: !specialContentDataNoAuth ? `cms/contentpage` : `cms/corepage`,
          params: alias ? { alias } : { corePageId: _sitepageid },
        });
        const { data: _data } = response;
        setIsBody(_data.Body !== '');
        if (_data.PageAlias && _data.PageAlias !== _sitepageid) {
          setCanonicalHREF(`${window.location.origin}/${_data.PageAlias}/`);
        }
        if (!_data.PageAlias || (_data.PageAlias && _data.PageAlias === _sitepageid)) {
          setCanonicalHREF(window.location.href);
        }
        if (renderOtherType && _data.Cat1Id.toString() in CAT_ID_MAP) {
          setCatId(_data.Cat1Id.toString());
          return;
        }
        setMeta({
          title: _data.MetaTitle,
          description: _data.MetaDescription,
        });
        setHero({
          headline: _data.HeroHeadline || _data.MetaTitle,
          description: _data.HeroParagraph,
          subTitle: _data.HeroSubtitle,
          image: {
            id: _data.HeroImageId,
            alt: _data.HeroImageAltText,
          },
        });
        setBody(
          mutateBody(_data.Body)
            .replace(/<br><br>/g, "<div class='lineBreak'></div>")
            .replace(/<br><\/p>/g, '</p>')
            .replace(/src="\/pciwebsite/g, `src="${legacyRoot}/pciwebsite`)
        );
        if (_data.ContentGroupItems && _data.ContentGroupIndex === 'Y') {
          setContentGroupItems(
            _data.ContentGroupItems.filter(items => items.ContentItemId !== _sitepageid)
          );
        }
        if (_data.ContentGroupIndex !== 'Y') {
          setContentGroupItems([]);
        }
        const {
          DisplayListTitle,
          DisplayListTop,
          BulletedFormat,
          IncludeRelated,
          CurrentSubjects,
          Publications,
          ContentGroups,
          ContentGroupItems,
        } = _data;
        if (IncludeRelated === 'Y') {
          const fieldOptions = {
            Subjects: ContentGroups ? [] : CurrentSubjects,
            ContentItems: ContentGroups
              ? ContentGroupItems.filter(element => {
                return element.ContentItemId.toString() === _sitepageid.toString();
              })
              : [],
            Publications: [...(ContentGroups || !Publications.length ? [] : Publications)],
          };
          const { data: relatedRes } = await publicSearch(fieldOptions);
          setRelatedMatters(relatedRes.Results);
          setDisplayListTitle(DisplayListTitle);
          setDisplayListTop(DisplayListTop);
          setBulletedFormat(BulletedFormat);
        } else {
          setRelatedMatters([]);
          setDisplayListTitle([]);
          setDisplayListTop([]);
          setBulletedFormat([]);
        }
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [_sitepageid, alias, roles]);

  useEffect(() => {
    if (renderOtherType && catId) {
      (async () => {
        const cat = CAT_ID_MAP[catId];
        const response = await request({
          url: `cms/${cat}`,
          params: {
            sitePageId: _sitepageid,
          },
        });
        if (response.data.SitePageId === 0) {
          setDoesntExist(true);
        } else {
          const catRoute = (() => {
            switch (catId) {
              case '9998':
                return 'media/news-releases/release';
              case '9997':
                return 'events/meetings/meeting';
              case '9996':
                return 'publications';
              default:
                return '';
            }
          })();
          if (catRoute) {
            router.replace(`/${catRoute}/[slug]`, `/${catRoute}/${response.data.SitePageId}`);
            return;
          }
          // setData(response.data);
        }
        setLoading(false);
      })();
    }
  }, [renderOtherType, catId]);

  if (unauthorizedError) return <Error status={401.4} />;
  if (error || doesntExist) return <Error status={404} />;

  return (
    <Layout>
      <Head title={meta.title} description={meta.description}>
        {canonicalHREF && <link rel="canonical" href={canonicalHREF} />}
      </Head>
      <AdminLink pageID={sitepageid} pageType="content" />
      <Hero
        title={loading ? <Skeleton title={{ width: '50%' }} paragraph={false} /> : hero.headline}
        subTitle={!loading && hero.subTitle}
        type={hero.image.id ? 'image' : 'default'}
        bgImage={`${process.env.API_URL}image/file?area=c&id=${hero.image.id}`}
      >
        {loading ? (
          <Skeleton title={false} paragraph={{ rows: 1, width: '75%' }} />
        ) : (
          <Sanitize>{hero.description}</Sanitize>
        )}
      </Hero>
      {renderBeforeContent()}

      <Section bgColor="gray">
        {displayListTop === 'Y' && bulletedFormat !== 'Y' && relatedSubjectMatters?.length > 0 && (
          <ListPagePaginated
            renderBeforeList={() => {
              if (!displayListTitle) return null;
              return (
                <Heading css={style.relatedTitle} type={3} theme="primaryRed">
                  {displayListTitle}
                </Heading>
              );
            }}
            results={relatedSubjectMatters}
            truncateResultSummary
            pageSize={10}
            loading={false}
            noHero
          />
        )}

        {isBody && (
          <Container
            size="sm"
            css={css`
              font-size: 1.6rem;
            `}
          >
            <main css={[style.body, bodyStyle]}>
              {displayListTop === 'Y' &&
                bulletedFormat === 'Y' &&
                relatedSubjectMatters?.length > 0 && (
                  <div css={style.bulletedListTop}>
                    <BulletedListPage
                      renderBeforeList={() => {
                        if (!displayListTitle) return null;
                        return (
                          <Heading css={style.relatedTitle} type={3} theme="primaryRed">
                            {displayListTitle}
                          </Heading>
                        );
                      }}
                      results={relatedSubjectMatters}
                      truncateResultSummary
                      loading={false}
                      noHero
                    />
                  </div>
                )}

              {loading ? (
                <Skeleton paragraph={{ rows: 12 }} active />
              ) : (
                <Sanitize mildwest>{body}</Sanitize>
              )}

              {displayListTop !== 'Y' &&
                bulletedFormat === 'Y' &&
                relatedSubjectMatters?.length > 0 && (
                  <div css={style.bulletedListBottom}>
                    <BulletedListPage
                      renderBeforeList={() => {
                        if (!displayListTitle) return null;
                        return (
                          <Heading css={style.relatedTitle} type={3} theme="primaryRed">
                            {displayListTitle}
                          </Heading>
                        );
                      }}
                      results={relatedSubjectMatters}
                      truncateResultSummary
                      loading={false}
                      noHero
                    />
                  </div>
                )}
            </main>
          </Container>
        )}
      </Section>

      {contentGroupItems.length > 0 && (
        <Section bgColor="gray">
          <Container>
            <main css={isBody ? [style.contentGroupBody] : null}>
              <ContentGroupItemsControl contentGroupItems={contentGroupItems} />
            </main>
          </Container>
        </Section>
      )}

      {specialContentDataNoAuth && (
        <Section bgColor="gray">
          <Container>
            <main css={[style.contentGroupBody]}>
              <Whitepapers whitepapers={specialContentDataNoAuth} />
            </main>
          </Container>
        </Section>
      )}

      {displayListTop !== 'Y' && bulletedFormat !== 'Y' && relatedSubjectMatters?.length > 0 && (
        <ListPagePaginated
          renderBeforeList={() => {
            if (!displayListTitle) return null;
            return (
              <Heading css={style.relatedTitle} type={3} theme="primaryRed">
                {displayListTitle}
              </Heading>
            );
          }}
          results={relatedSubjectMatters}
          truncateResultSummary
          pageSize={10}
          loading={false}
          noHero
        />
      )}

      <WebLogImage browserPath sitePageId={sitepageid} title={meta.title} />
    </Layout>
  );
};

ContentPage.propTypes = {
  sitepageid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alias: PropTypes.string,
  bodyStyle: PropTypes.any,
  mutateBody: PropTypes.func,
  renderBeforeContent: PropTypes.func,
  renderOtherType: PropTypes.bool,
};

ContentPage.defaultProps = {
  sitepageid: null,
  bodyStyle: css``,
  alias: null,
  mutateBody: body => body,
  renderBeforeContent: () => null,
  renderOtherType: false,
};

export default connect(
  state => {
    return {
      roles: state.auth.role,
      isLoggedIn: state.auth.isLoggedIn,
      isLoading: state.auth.isLoading,
    };
  },
  null
)(ContentPage);
