import PropTypes from 'prop-types';
import React from 'react';

import BulletedPage from './index';

function BulletedListPage(props) {
  return (
    <BulletedPage
      {...props}
    />
  );
}

BulletedListPage.propTypes = {
  heroContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSize: PropTypes.number,
  renderResultSubtitle: PropTypes.func,
  truncateResultSummary: PropTypes.bool,
};

BulletedListPage.defaultProps = {
  heroContent: null,
  pageSize: 20,
  renderResultSubtitle: result => new Date(result.PublishDate).toLocaleDateString(),
  truncateResultSummary: false,
};

export default BulletedListPage;
