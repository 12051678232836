import { useAuth0 } from '@auth0/auth0-react';
import { css, jsx } from '@emotion/core';
import { Icon, Tooltip } from 'antd';
import Link from 'next/link';
import Router from 'next/router';
import React from 'react';
import { connect } from 'react-redux';

import Button from '@/src/shared/components/Button';
import btnStyle from '@/src/shared/components/Button/style';
import { bp, colors } from '@/styles';

const style = {
  slide: css`
    box-sizing: content-box;
    width: 222px;
    height: 236px;
    padding: 12px;
    background-color: white;
    box-shadow: 0 6px 12px 0 rgba(35, 32, 32, 0.1);
  `,
  tagItems: css`
    height: 22px;
    margin-top: 5px;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  tagItem: css`
    border-radius: 25px;
    background: ${colors.accentGrayUltralight};
    font-size: 1.2rem;
    font-weight: 800;
    padding: 4px;
    margin-right: 5px;
    height: 5px;
  `,
  title: css`
    font-weight: 900;
    margin-bottom: 1.6rem;
    font-size: 1.7rem;
    color: ${colors.secondaryBlueDark};
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  titleDiv: css`
    height: 50%;
  `,
  date: css`
    font-size: 1.2rem;
    padding-bottom: 25px;
  `,
  download: css`
    padding-left: 5px;
  `,
  link: css`
    font-size: 1.4rem !important;
    max-height: 32px;
  `,
};

const AttachmentSlide = props => {
  const { Title, Date, ButtonText, ButtonUrl, OpenInNewTab, Tags, MemberAccessLevels } = props;

  const { loginWithRedirect } = useAuth0();

  const splitTags = Tags && Tags.includes(',') && Tags.split(',');
  let tooltipTags = [];
  let plusMore = 0;

  if (splitTags && splitTags.length > 2) {
    plusMore = splitTags.length - 2;
    const moreLabel = `+${plusMore} more`;
    tooltipTags = splitTags.splice(2, splitTags.length);
    splitTags.push(moreLabel);
  }

  const displayTags = Tags && !Tags.includes(',') ? [Tags] : splitTags;

  const tagTooltip = tooltipTags.map((item, index) => {
    return (
      <>
        {index + 1 !== tooltipTags.length && <>{item},</>}
        {index + 1 === tooltipTags.length && <>{item}</>}
      </>
    );
  });

  const isMemberAccessLevels = MemberAccessLevels.length > 0;
  const { isLoggedIn, role } = props;
  let hasAccessToContent = false;
  if (MemberAccessLevels !== undefined && MemberAccessLevels?.length !== 0) {
    hasAccessToContent = MemberAccessLevels.some(level => role.includes(level.WebAccessName));
  }
  const linkProp = OpenInNewTab === 'Y' && isLoggedIn ? { target: '_blank' } : {};

  return (
    <div css={style.slide}>
      <div css={style.tagItems}>
        {displayTags &&
          displayTags.map(item => {
            return (
              <>
                {item === `+${plusMore} more` && (
                  <Tooltip title={tagTooltip}>
                    <span css={style.tagItem}>{item}</span>
                  </Tooltip>
                )}
                {item !== `+${plusMore} more` && <span css={style.tagItem}>{item}</span>}
              </>
            );
          })}
      </div>
      {Title && (
        <div css={style.titleDiv}>
          <span css={style.title}>{Title}</span>
        </div>
      )}
      {Date && <div css={style.date}>Published on {Date}</div>}
      {isMemberAccessLevels && !isLoggedIn && (
        <Button
          css={[btnStyle.type.default, btnStyle.size.full, style.link]}
          onClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: Router.pathname,
              },
            })
          }
          {...linkProp}
        >
          {ButtonText}
          <Icon css={style.download} type="lock" />
        </Button>
      )}
      {(!isMemberAccessLevels || (isMemberAccessLevels && isLoggedIn && hasAccessToContent)) && (
        <Link href={ButtonUrl} passHref>
          <a
            css={[btnStyle.type.default, btnStyle.size.full, style.link]}
            href={ButtonUrl}
            {...linkProp}
          >
            {ButtonText}
            <Icon css={style.download} type="download" />
          </a>
        </Link>
      )}
      {isLoggedIn && isMemberAccessLevels && !hasAccessToContent && (
        <Tooltip title="Restricted Access. This content is not included in your company’s APCIA subscription.">
          <div css={[btnStyle.type.default, btnStyle.size.full, style.link]}>
            {ButtonText}
            <Icon css={style.download} type="lock" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default connect(
  state => {
    const {
      auth: { isLoggedIn, role },
    } = state;
    return { isLoggedIn, role };
  },
  null
)(AttachmentSlide);
