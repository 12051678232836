import { css } from '@emotion/core';
import { Pagination } from 'antd';
import React from 'react';

import { bp } from '@/styles';
import { convertMomentToDate } from '@/utils/dateTimeHelpers';

import AttachmentSlide from './attachmentSlide';

const style = {
  cardContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    @media ${bp.md} {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media print {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }
  `,
  slide: css`
    padding: 5px;
  `,
};

const AttachmentList = props => {
  const { results, handlePaginationChange, current, pageSize } = props;

  const filteredResults = (results || []).slice((current - 1) * pageSize, current * pageSize);

  return (
    <>
      <div css={style.cardContainer}>
        {filteredResults &&
          filteredResults.map(item => {
            return (
              <p css={style.slide}>
                <AttachmentSlide
                  Title={item.FileTitle ? item.FileTitle : item.FileName}
                  Date={convertMomentToDate(item.PublishDate ? item.PublishDate : item.DateCreated)}
                  ButtonText="Download"
                  ButtonUrl={item.Url}
                  OpenInNewTab="Y"
                  Tags={item.Tags}
                  MemberAccessLevels={item.MemberAccessLevels}
                />
              </p>
            );
          })}
      </div>
      {results.length > 9 && (
        <Pagination
          total={results.length}
          pageSize={pageSize}
          onChange={handlePaginationChange}
          showTotal={(total, range) => `Results ${range[0]}-${range[1]} of ${total} items`}
          current={current}
        />
      )}
    </>
  );
};

AttachmentList.defaultProps = {
  current: 1,
  pageSize: 9,
  handlePaginationChange: () => {},
};

export default AttachmentList;
