import { css, jsx } from '@emotion/core';
import React from 'react';

import ResultCard from '@/src/shared/components/ResultCard';
import Sanitize from '@/src/shared/components/Sanitize';
import { bp } from '@/styles';

const style = {
  cardsContainer: css`
    display: block;
    max-width: 768px;
    margin: 0 auto;
    padding-top: 65px;
    @media ${bp.sm} {
      padding-top: 0px;
    }
  `,
  buttonContainer: css`
    margin-top: 40px;
    max-width: 768px;
    width: 100%;
    display: none;
    @media ${bp.md} {
      display: block;
    }
  `,
  viewButton: css`
    width: 100%;
    max-width: 343px;
    margin: 24px 16px 0px;
    height: 43px !important;
    display: block;
    @media ${bp.md} {
      display: none;
    }
    @media print {
      display: none;
    }
  `,
};

const ContentGroupItemCards = props => {
  const { contentGroupItems } = props;
  return (
    <div css={style.cardsContainer}>
      {contentGroupItems.map(groupItem => {
        const groupItemsArray = <Sanitize>{groupItem.ContentItemDescription}</Sanitize>;
        const imageSrc = groupItem.ContentGroupImageId
          ? `${process.env.API_ROOT}/pciwebsite/api/image/file?area=c&id=${
              groupItem.ContentGroupImageId
            }`
          : null;
        return (
          <ResultCard
            key={groupItem.ContentItemId}
            title={groupItem.ContentItem}
            iconId={groupItem.IconId}
            summary={groupItemsArray}
            truncate
            summaryLimit={200}
            href={`/${groupItem.ContentItemSlugPath}`}
            imageSource={imageSrc}
            learnMoreLink
          />
        );
      })}
    </div>
  );
};

export default ContentGroupItemCards;
